import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import keycloak from "./keycloak";
import { BrowserRouter } from "react-router-dom";

keycloak.init({ onLoad: "login-required" }).then((authenticated) => {
  if (!authenticated) {
    window.location.reload();
  } else {
    ReactDOM.render(
      <BrowserRouter>
        <App />
      </BrowserRouter>,
      document.getElementById("root")
    );
  }
}).catch((error) => {
  console.error("Keycloak initialization failed", error);
});
