// src/App.js
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import keycloak from "./keycloak";
import Header from "./components/Header";
function App() {
  return (
    <>
    <Header/>
    <div style={{ paddingTop: "70px" }}>\
    <Routes>
     <Route path="/dashboard" element={keycloak.authenticated ? <Dashboard /> : <Navigate to="/" />} />
      <Route path="/" element={<Navigate to="/dashboard" replace />} />
    </Routes>
    </div>
    </>
  );
}

export default App;
