import React from "react";
import { Container, Grid, Card, CardContent, Typography, CardActionArea, CardMedia, Box } from "@mui/material";
import "./Dashboard.css";

const apps = [
  { 
    name: "Minervaa", 
    url: process.env.MINERVAA_SERVER_URL || "https://minervaa.enigmaa.in",
    description: "Minervaa is a framework designed for evaluating applications using large language models (LLMs). It simplifies the process of building and refining LLM applications",
    image: require("../assets/images/minervaa.jpg") 
  },
  { 
    name: "Flowise", 
    url: process.env.FLOWISE_SERVER_URL || "https://flowise.enigmaa.in", 
    description: "An open source low-code tool for developers to build customized LLM orchestration flows & AI agents.",
    image: require("../assets/images/flowise.jpg") 
  },
  { 
    name: "Langfuse", 
    url: process.env.LANGFUSE_SERVER_URL || "http://devtools.enigmaa.in/langfuse", 
    description: "An open source tool for developers to Traces, evals, prompt management and metrics to debug and improve your LLM application.",
    image: require("../assets/images/langfuse.png") 
  }
];

const Dashboard = () => {
  const handleAppClick = (url) => {
    window.open(url, "_blank"); 
  };

  return (
    <div className="dashboard-background">
    <Container className="dashboard">
      <Box display="flex" justifyContent="center" alignItems="center" mb={10}>
        <Typography  variant="h4">Application Dashboard</Typography>
      </Box>

      <div className="app-cards">
        <Grid container spacing={3} justifyContent="center">
          {apps.map((app, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ maxWidth: 345, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', transition: 'transform 0.3s', "&:hover": { transform: 'scale(1.05)' }, display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
                <CardActionArea onClick={() => handleAppClick(app.url)} style={{ display: 'flex', flexDirection: 'column', height: '100%',width: '100%' }}>
                  <CardMedia
                    component="img"
                    height="140"
                    image={app.image}
                    alt={app.name}
                  />
                  <CardContent style={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="div">
                      {app.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="card-description">
                      {app.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Container>
    </div>
  );
};

export default Dashboard;
